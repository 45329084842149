.mytable {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

td {
  padding: 10px;
  border-bottom: 1px solid #4d7feb;
}

th {
  text-align: left;
}

.highlighted {
  background-color: rgba(255, 144, 0, 0.6);
}

.mytable tr {
  cursor: pointer;
}

.container {
  background-color: white;
  padding: 24px;
  width: 100%;
  height: 100%;
}

.content {
  background-color: #d3d3d3;
  padding: 8px;
}

h1 {
  font-size: 1.2em;
  font-weight: bold;
}
